<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current stroke-current text-${color}`" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.80781 10.0234L5.80818 10.0234C5.88318 10.0222 5.95705 10.0049 6.02478 9.97272C6.09252 9.94049 6.15253 9.89408 6.20076 9.83664L10.4008 4.83664L10.3828 4.82156L10.401 4.83638C10.4879 4.72981 10.5294 4.59333 10.5165 4.45642C10.5036 4.31951 10.4373 4.19318 10.332 4.10473C10.2267 4.01628 10.0909 3.97282 9.95378 3.98375C9.8167 3.99467 9.68942 4.0591 9.59946 4.1631L9.59924 4.16336L5.78564 8.70241L4.38872 7.15036C4.38866 7.15028 4.38859 7.15021 4.38853 7.15014C4.34312 7.09731 4.28761 7.05408 4.22527 7.02298C4.16285 6.99184 4.09483 6.97349 4.02521 6.96902C3.95559 6.96454 3.88578 6.97402 3.81989 6.99691C3.75399 7.0198 3.69332 7.05562 3.64147 7.10229C3.58961 7.14895 3.54761 7.20552 3.51793 7.26865C3.48824 7.33178 3.47148 7.4002 3.46862 7.46991C3.46575 7.53961 3.47685 7.60918 3.50127 7.67453C3.52564 7.73979 3.5628 7.79953 3.61057 7.85025C3.61063 7.85032 3.61069 7.85039 3.61076 7.85045L5.41039 9.85005C5.45945 9.9046 5.51943 9.94822 5.58645 9.97809C5.65346 10.008 5.726 10.0234 5.79937 10.0234L5.80781 10.0234ZM0.523438 7C0.523438 3.42888 3.42888 0.523438 7 0.523438C10.5711 0.523438 13.4766 3.42888 13.4766 7C13.4766 10.5711 10.5711 13.4766 7 13.4766C3.42888 13.4766 0.523438 10.5711 0.523438 7Z"
      stroke-width="0.046875"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: String,
      default: '14'
    },
    height: {
      type: String,
      default: '14'
    }
  }
}
</script>
